import React, { useState, useEffect } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const PaginationComponent = ({ skip, limit, handlePageClick, totalItems }) => {
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        setTotalPages(totalItems > 0 ? Math.ceil(totalItems / limit) : 0);
    }, [totalItems, limit]);

    if (totalPages <= 1 || totalItems === 0) {
        return null; // Скрыть пагинацию, если страниц 1 или 0
    }

    const currentPage = Math.floor(skip / limit);
    const isFirstPage = currentPage === 0;
    const isLastPage = currentPage === totalPages - 1;
    const lastPageSkip = (totalPages - 1) * limit;

    // Функция для рендера номеров страниц с "..."
    const renderPageNumbers = () => {
        const pages = [];
        const delta = 2; // Количество страниц до и после текущей

        for (let i = 0; i < totalPages; i++) {
            if (
                i === 0 || // Первая страница
                i === totalPages - 1 || // Последняя страница
                (i >= currentPage - delta && i <= currentPage + delta) // Текущая + 2 рядом
            ) {
                pages.push(i);
            } else if (
                pages[pages.length - 1] !== '...' // Добавляем многоточие только 1 раз подряд
            ) {
                pages.push('...');
            }
        }

        return pages.map((page, index) => (
            <PaginationItem key={index} active={page === currentPage} disabled={page === '...'}>
                {page === '...' ? (
                    <PaginationLink href="#" disabled>
                        ...
                    </PaginationLink>
                ) : (
                    <PaginationLink href="#" onClick={() => handlePageClick(page * limit)}>
                        {page + 1}
                    </PaginationLink>
                )}
            </PaginationItem>
        ));
    };

    return (
        <Pagination aria-label="Page navigation example">
            {/* Кнопка "პირველი" (Первая страница) */}
            <PaginationItem disabled={isFirstPage}>
                <PaginationLink href="#" onClick={() => handlePageClick(0)}>
                    პირველი
                </PaginationLink>
            </PaginationItem>

            {/* Кнопка "წინ" (Назад) */}
            <PaginationItem disabled={isFirstPage}>
                <PaginationLink href="#" onClick={() => handlePageClick(Math.max(skip - limit, 0))}>
                    წინ
                </PaginationLink>
            </PaginationItem>

            {/* Динамический список страниц */}
            {renderPageNumbers()}

            {/* Кнопка "შემდეგი" (Следующая) */}
            <PaginationItem disabled={isLastPage}>
                <PaginationLink href="#" onClick={() => handlePageClick(Math.min(skip + limit, lastPageSkip))}>
                    შემდეგი
                </PaginationLink>
            </PaginationItem>

            {/* Кнопка "ბოლოს" (Последняя страница) */}
            <PaginationItem disabled={isLastPage}>
                <PaginationLink href="#" onClick={() => handlePageClick(lastPageSkip)}>
                    ბოლოს
                </PaginationLink>
            </PaginationItem>
        </Pagination>
    );
};

export default PaginationComponent;
