import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button, Card, CardBody, Col, FormGroup, Label, Row,} from "reactstrap";
import ReactExport from "react-export-excel";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {courierFinish, getCouriers, getUsers} from "../../store/actions";
import {city} from '../../helpers/status'
import ObjectColumns from "./ObjectColumns";
import ClipLoader from "react-spinners/ClipLoader";
import {shop} from "../../helpers/status";
import SweetAlert from "react-bootstrap-sweetalert";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Courier = props => {
    const dispatch = useDispatch();
    const [skip, setSkip] = useState(0)
    const [type, setType] = useState('all')
    const [limit, setLimit] = useState(100)

    const [confirm_alert, setconfirm_alert] = useState(false)
    const [branches, setBranches] = useState('all')


    useEffect(() => {
        let item = {
            branch : branches,
            type: type,
            skip: skip,
            limit: limit,
        }
        dispatch(getCouriers(item))
    }, [])

    useEffect(() => {
        let item = {
            branch : branches,
            type: type,
            skip: skip,
            limit: limit,
        }
        dispatch(getCouriers(item))
    }, [branches])



    const handleFinishCourier = () =>{
         let item = {
            branch:branches
        }
        setconfirm_alert(false)
       dispatch(courierFinish(item))
    }

    const handleAlert = () =>{
        setconfirm_alert(true)
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="სია" breadcrumbItem="საკურიერო"/>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <div className="button-items">
                                        <Button
                                            type="button"
                                            color="primary"
                                            className="btn btn-success w-lg waves-effect waves-light"
                                            // disabled={props.logistic.preloaderAll}
                                             onClick={handleAlert}
                                        >
                                            <span> დასრულება</span>
                                        </Button>{" "}
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>

                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <Label htmlFor="validationCustom02">ფილიალი</Label>
                                    <select
                                        value={branches}
                                        onChange={(e) => setBranches(e.target.value)}
                                        name="branch"
                                        className="form-control">
                                        <option value="all">ყველა</option>
                                        <option value="didube" >დიდუბე</option>
                                        <option value="gldani" >გლდანი</option>
                                        <option value="isani" >ისანი</option>
                                        <option value="kutaisi_1" >ქუთაისი 1</option>
                                    </select>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <ExcelFile
                                        element={<button className='waves-effect waves-light btn btn-primary'>Download EXEL files</button>}>
                                        <ExcelSheet data={props.Courier.array} name="Employees">

                                            <ExcelColumn label="ქალაქი"
                                                         value={(col) => col.city ? `${city(col.city)}` : `` } />
                                            <ExcelColumn label="სახელი და გვარი" value="name"/>
                                            <ExcelColumn label="ტელეფონის ნომერი" value="phone"/>
                                            <ExcelColumn label="მისამართი" value="address"/>
                                            <ExcelColumn label="ფილიალი" value="branch"/>
                                            <ExcelColumn label="დამატებითი ინფორმაცია"
                                                         value={(col) => col.status ? `იხდის მიმღები` : `იხდის მიმღები` }/>

                                        </ExcelSheet>
                                    </ExcelFile>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <ObjectColumns
                                // onRowSelect={onRowSelect}
                                // onSelectAll={onSelectAll}
                                page='index'
                                array={props.Courier.array}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
            {confirm_alert ? (
                <SweetAlert
                    title="დარწმუნებული ხართ?"
                    warning
                    showCancel
                    confirmButtonText="Yes, delete it!"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={handleFinishCourier}
                    onCancel={() => setconfirm_alert(false)}
                >
                    რომ გსურთ დაასრულოთ ოპერაცია?!
                </SweetAlert>
            ) : null}
        </React.Fragment>

    )
}


const mapStateToProps = state => {
    const {
        Courier,
    } = state
    return {
        Courier
    }
};
export default withRouter(connect(mapStateToProps)(Courier));
